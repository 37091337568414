body {
    /*background: rgb(143,224,255);*/
    /*background: linear-gradient(90deg, rgba(143,224,255,1) 0%, rgba(137,137,255,1) 50%, rgba(0,212,255,1) 100%);*/
    background: url("../images/hero-bg-2.jpg") no-repeat;
    background-size: cover;
    height: 100vh;
    font-family: "Times New Roman", serif !important;
}

fieldset {
    border: 1px solid #9fcdff !important;
    width: 100%;
    height: 100%;
    padding: 20px !important;
}

#listenerFS {
    border: 1px solid #9fcdff !important;
    width: 100%;
    height: 100%;
    padding: 10px !important;
}

legend {
    width: auto !important;
    padding: 0 5px !important;
    margin-bottom: 0;
}

#root {
    /*width: 100vh;*/
    margin-left: auto;
    margin-right: auto;
}

.filter-btn {
    width: 40px;
    height: 40px;
}

.form-signin {
    width: 20rem;
    padding: 15px;
    margin: 0 auto;
    border: 3px solid #005aff;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.7);
}

.form-signin .checkbox {
    font-weight: 400;
}

.form-signin .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
}

.form-signin .form-control:focus {
    z-index: 2;
}

.form-signin input[type="text"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

/*Menu styles*/
h1, h2, p, ul, li {
    font-family: sans-serif;
}
ul.header li {
    display: inline;
    list-style-type: none;
    margin: 0;
}
ul.header {
    background-image: linear-gradient(-180deg, #f3f3f3, #dee2e6);
    box-shadow: 0 .025rem .55rem rgba(0,0,0,.45)!important;
    padding: 0;
    height: 40px;
    /*opacity: .7;*/
}
{
    color: #fff;
    font-weight: 400;
    text-decoration: none;
    padding: 6px;
    font-size: 1rem;
    display: inline-block;
}

ul.header li span {
    font-weight: bold;
    padding: 15px;
    float: right !important;
}

ul.header li a.active {
    color: #FFFFFF;
    background-color: #17a2b8;
}

/*.form-row {
    margin-bottom: .7rem;
}*/

label {
    margin-bottom: 0;
}

.content {
    /*overflow-y: auto;*/
    /*height: calc(100vh - 40px);*/
}
.content h2 {
    padding: 0;
    margin: 0;
}
.content li {
    margin-bottom: 10px;
}

#searchBox {
    border-color: #17a2b8;
}

.auto-margin {
    margin: auto;
}

#listenerTable {
    border-radius: 7px;
}
#listenerTable tr th, tr td {
    border: .3pt solid black;
    border-collapse: collapse;
}

#listenerTable .form-control {
    border: none;
}

#noBorder tr td {
    border: none;
}

.linkStyle {
    text-decoration: underline;
    color: #0033ff;
    cursor: pointer;
}

.dropdown-divider {
    height: 0;
    margin: .1rem 0;
    overflow: hidden;
    border-top: 1px solid #d1d1d1;
}

.centered-box {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.topic-name {
    cursor: pointer;
    border-top: 1px solid #00BFFF;
    border-right: 1px solid #00BFFF;
    border-left: 1px solid #00BFFF;
    border-radius: 5px;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.4), 4px 6px 20px 0 rgba(0, 0, 0, 0.4);
}

/*.topic-name:hover {
    background: rgb(214, 214, 214);
    box-shadow: 6px 6px 8px 6px rgba(0, 0, 0, 0.5), 6px 6px 20px 6px rgba(0, 0, 0, 0.5);
}*/

.help {
    width: 100px;
    position: fixed;
    right: 10px;
    z-index: 2;
    cursor: pointer;
    bottom: 0;
}

.help-page {
    width: 98%;
    margin-left: auto;
    margin-right: auto;
}

.react-responsive-modal-modal {
    max-width: 800px;
    min-width: 400px;
    border-radius: 10px;
    position: relative;
    padding: 1.2rem;
    background: #ffffff;
    background-clip: padding-box;
    box-shadow: 0 12px 15px 0 rgb(0 0 0 / 25%);
    margin: 0 auto;
}

ul.header {
    background-image: linear-gradient(
            -180deg
            , #77fff26b, #eaeaea);
    padding: 0;
    margin: 0;
    box-shadow: none !important;
}

ul.header>li>span.linkStyle {
    text-decoration: underline;
    color: #000000;
    cursor: pointer;
}

ul.header li a.active {
    color: #FFFFFF;
    background-image: linear-gradient(
            -180deg
            , #4ee6d8, #cbf6f6);
}

table thead tr th {
    text-align: center !important;
    vertical-align: middle !important;
}

.item-btn {
    width: 20px;
    height: 27px;
	background-color: #00BFFF;
	border: 1px solid #0000FF;
	border-radius: 5px;
    text-align: center;
    cursor: pointer;
}

ul.pagination {
    display: inline-block;
    margin-bottom: 0;
}

ul.pagination li.previous, li.next {
    font-family: cursive;
}

ul.pagination li {
    padding: 0 8px;
    display: inline-block;
    border-right-width: 0;
    cursor: pointer;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

ul.pagination li:first-child, ul.pagination li:last-child {
    padding: 5px 10px;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

ul.pagination li.active {
    padding: 5px 10px;
    display: inline-block;
    border-radius: 5px;
    cursor: pointer;
    background-color: #00BFFF;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.break-me {
    font-weight: bold;
}

.row {
    margin: 0 5px 0 5px !important
}

.col-* {
    padding: 0 !important;
}

.col-md-2, .col-md-3, .col-md-6, .col-md-12 {
    padding: 0 !important;
}

.box1 {
    background: url("../images/mediatsiya.png") no-repeat top;
}

.box2 {
    background: url("../images/qtayyorlash.png") no-repeat top;
}

.box3 {
    background: url("../images/moshirish.png") no-repeat top;
}

.box4 {
    background: url("../images/qmkurs.png") no-repeat top;
}

.boxName {
    margin: 1rem;
    padding: 1rem;
    height: auto;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
    font-size: 28px;
    text-align: center;
    text-shadow: 0 0 3px #FF0000, 1px 1px 5px #0000FF;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.4), 4px 6px 20px 0 rgba(0, 0, 0, 0.4);
}

.boxName span {
    left: 0;
    bottom: 0;
    width: 100%;
}

.bottomAlign {
    position: absolute;
    bottom: 0;
}

.autocomplete-wrapper {
    width: 350px;
    position: relative;
    display: inline-block;
}

.autocomplete-wrapper > div {
    width: 100%;
}

.autocomplete-wrapper input {
    border: 1px solid #cecece;
    padding: 12px 15px;
    font-size: 14px;
    width: 100%;
}

.autocomplete-wrapper input:focus {
    border-color: #0F67FF;
    box-shadow: none;
    outline: none;
}

.autocomplete-wrapper .dropdown {
    width: 100%;
    padding: 0;
    text-align: left;
    max-height: 280px;
    overflow: hidden;
    overflow-y: auto;
    background-color: #ffffff;
    border: 1px solid #0F67FF;
    border-top: none;
}

.autocomplete-wrapper .item  {
    display: block;
    cursor: pointer;
    font-size: 14px;
    padding: 15px;
}

.autocomplete-wrapper .item.selected-item {
    background-color: #0069ff;
    color: #FAFBFC;
}

.autocomplete-wrapper .item:hover {
    background-color: #0069ff;
    color: #FAFBFC;
}

.listing-page-item {
    height: 40px;
    width: 40px;
    padding: 0;
    border: 0;
    margin: 0;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-radius: 50% !important;
    background-color: #083f88;
    color: #fff;
}

.listing-page-item a {
    text-decoration: none !important;
}

.c-btn {
    padding: 0.2rem !important;
    cursor: pointer;
    color: black;
}

.components {

}

.components legend {
    float: none;
}

.components .left {
    width: calc(50% - 5px);
    margin-right: 5px;
}

.components .right {
    width: calc(50% - 5px);
    margin-left: 5px;
}

.ml-5px {
    margin-left: 5px;
}

.ct-pointer {
    cursor: pointer;
}